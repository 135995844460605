.home-category-teaser {
  $root: &;
  display: flex;
  flex-wrap: wrap;
  margin: -8px auto;
  padding: 32px 0;

  @media (min-width: $screen-mobile-large) and (max-width: $screen-tablet-landscape - 1) {
    min-width: 400px;
    max-width: 80% !important;
  }

  @media (min-width: $screen-tablet-portrait) {
    padding: 40px 0;
  }

  &__container {
    display: flex;
    width: 100%;

    &:first-child {
      border-bottom: $brownColor 1px solid;
      padding-bottom: 16px;
      margin-bottom: 16px;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: 50%;

      &:first-child {
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  &__item {
    position: relative;
    text-decoration: none !important;
    width: 50%;
    padding: 0;
    text-align: center;
    overflow: hidden;

    &:nth-child(odd) {
      border-right: $brownColor 1px solid;
    }

    &:nth-child(n+3) {
      border-top: $brownColor 1px solid;
    }

    @media (min-width: $screen-tablet-landscape) {
      border-right: $brownColor 1px solid;
      border-top: 0 !important;
      padding: 16px;

      #{$root}__container:last-child &:last-child {
        border-right: 0;
      }
    }
  }

  &__title-image {
    height: 115px;
    padding: 4px 0;

    @media (min-width: $screen-tablet-portrait) {
      height: 174px;
    }
  }

  &__glass,
  &__glass2 {
    height: 100px;
    display: inline-block;

    @media (min-width: $screen-tablet-portrait) {
      height: 160px;
    }

    &::after {
      content: '';
      background: #0000 radial-gradient(ellipse at center center, #0003 35%, #0000 70%); // stylelint-disable-line  plugin/no-unsupported-browser-features
      background-size: contain;
      display: block;
      height: 12px;
      width: 100%;
      transform: translate(-4px, -12px);
      transition: opacity .3s ease-in-out;
    }

    img {
      height: 100%;
      width: auto;
      transform-origin: center 80%;
      transition: transform .3s ease-in-out;
      position: relative;
      z-index: 1;
    }
  }

  &__item--red {
    #{$root}__glass {
      transform: translate(-8px, 6px);
      margin-right: -16%;

      img {
        transform: translateY(-3px) rotate(-6deg);
      }
    }

    &:hover #{$root}__glass {
      img {
        transform: translateY(-8px) rotate(-5deg);
      }

      &::after {
        opacity: .5;
      }
    }
  }

  &__item--white {
    #{$root}__glass {
      transform: translateX(-16px);

      @media (min-width: $screen-tablet-portrait) {
        transform: translate(-32px, 9px);
      }

      @media (min-width: $screen-tablet-landscape) {
        margin-right: -12%;
      }

      img {
        transform: translateY(-2px) rotate(5deg);
      }
    }

    &:hover #{$root}__glass {
      img {
        transform: translateY(-6px) rotate(8deg);
      }

      &::after {
        opacity: .6;
      }
    }
  }

  &__item--rose {
    #{$root}__glass {
      transform: translate(-8px, 5px);
      margin-right: -16%;

      img {
        transform: translateY(-4px) rotate(-8deg);
      }
    }

    &:hover #{$root}__glass {
      img {
        transform: translateY(-10px) rotate(-4deg);
      }

      &::after {
        opacity: .4;
      }
    }
  }

  &__item--cava {
    img {
      transition-timing-function: cubic-bezier(.4, .12, .95, .76);
    }

    #{$root}__glass {
      position: relative;
      z-index: 1;
      transform: translateX(-32px);

      @media (min-width: $screen-tablet-portrait) {
        transform: translateX(-40px);
      }

      img {
        transform: translate(0, -2px) rotate(3deg);
      }
    }

    &:hover #{$root}__glass {
      img {
        transform: translate(0, -6px) rotate(7deg);
      }

      &::after {
        opacity: .7;
      }
    }

    #{$root}__glass2 {
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(34px);

      @media (min-width: $screen-tablet-portrait) {
        transform: translateX(70px);
        bottom: 9px;
      }

      @media (min-width: $screen-tablet-landscape) {
        transform: translateX(64px);
        bottom: 22px;
      }

      img {
        transform: translate(0, -2px) rotate(-3deg);
      }
    }

    &:hover #{$root}__glass2 {
      img {
        transform: translate(-3px, -7px) rotate(-6deg);
      }

      &::after {
        opacity: .7;
      }
    }
  }
}
