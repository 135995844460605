.image-text-action-teaser {
  $root: &;

  &--default,
  &--variant1 {
    width: 100%;
    height: 100%;
    position: relative;
    border: $imageTextTeaserBorder;
    padding: $imageTextTeaserBorderPadding;

    #{$root}__link {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      text-decoration: none;
    }

    #{$root}__img {
      width: 100%;
      display: block;

      .image-element,
      img {
        width: 100%;
        display: inherit;
      }
    }

    #{$root}__box {
      background: transparent;
      padding: 0;
      position: relative;
      z-index: 1;
      min-height: 140px;
      flex-grow: 2;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;

      &--no-min-height {
        min-height: auto;
      }
    }

    #{$root}__header {
      @include teaserHeadline();
      font-family: $fontHeadlineFamily;
      color: $orangeColor;
      text-align: left;
      padding: 16px 0 0;
      line-height: 1.1;
      order: 1;

      @media (min-width: $screen-tablet-portrait) {
        padding: 24px 0 0;
      }
    }

    #{$root}__subline {
      @include teaserText();
      text-align: left;
      margin: 16px 0 0;
      flex-grow: 1;
      order: 2;

      @media (min-width: $screen-tablet-portrait) {
        margin: 24px 0 0;
      }
    }

    #{$root}__button {
      --button-secondary-text-color: #{$buttonPrimaryTextColor};
      --button-secondary-background-color: #{$buttonPrimaryBackgroundColor};
      --button-secondary-border-color: #{$buttonPrimaryBorderColor};
      --button-secondary-hover-background-color: #{$buttonPrimaryHoverBackgroundColor};
      --button-secondary-hover-text-color: #{$buttonPrimaryHoverTextColor};
      --button-secondary-active-background-color: #{$buttonPrimaryActiveBackgroundColor};
      order: 3;
      margin: 16px 0 0;

      @media (min-width: $screen-tablet-portrait) {
        margin: 24px 0 0;
      }
    }
  }

  &--default {
    .columns-1 & {
      #{$root}__header {
        padding: 0;
      }

      #{$root}__link {
        flex-direction: column;

        @media (min-width: $screen-tablet-portrait) {
          flex-direction: row;
        }
      }

      #{$root}__img-wrapper {
        width: 80%;
        order: 2;
        margin: 24px 0 0;

        @media (min-width: $screen-tablet-portrait) {
          width: 55%;
          order: 0;
          margin: 0;
        }
      }

      #{$root}__box {
        justify-content: flex-start;
        width: 100%;
        padding: 20px 0 0;
        z-index: 0;

        @media (min-width: $screen-tablet-portrait) {
          width: 45%;
          padding: 20px 0 60px 40px;
        }

        @media (min-width: $screen-tablet-landscape) {
          padding: 40px 40px 60px;
        }

        @media (min-width: $screen-desktop) {
          padding: 40px 60px 60px;
        }
      }

      #{$root}__subline {
        flex-grow: 0;
      }
    }

    .columns-1 .teasergroup_teaser__item:nth-child(even) & {
      #{$root}__link {
        @media (min-width: $screen-tablet-portrait) {
          flex-direction: row-reverse;
        }
      }

      #{$root}__box {
        align-items: flex-end;

        @media (min-width: $screen-tablet-portrait) {
          align-items: flex-start;
          padding: 0 20px 20px 0;
        }

        @media (min-width: $screen-tablet-landscape) {
          padding: 0 40px 40px;
        }

        @media (min-width: $screen-desktop) {
          padding: 0 60px 60px;
        }
      }

      #{$root}__img-wrapper {
        order: 0;
        margin: 0;
        align-self: flex-end;

        @media (min-width: $screen-tablet-portrait) {
          align-self: flex-start;
        }
      }

      #{$root}__img {
        margin: -40px 0 0;

        @media (min-width: $screen-tablet-portrait) {
          margin: -60px 0 0;
        }

        @media (min-width: $screen-tablet-landscape) {
          margin: -80px 0 0;
        }

        @media (min-width: $screen-desktop) {
          margin: -120px 0 0;
        }
      }

      #{$root}__subline {
        text-align: right;

        @media (min-width: $screen-tablet-portrait) {
          text-align: left;
        }
      }
    }

    .columns-1 .teasergroup_teaser__item:first-child & {
      #{$root}__box {
        padding-top: 0;

        @media (min-width: $screen-tablet-portrait) {
          padding-top: 20px;
        }

        @media (min-width: $screen-desktop) {
          padding-top: 40px;
        }
      }
    }

    .columns-1 .teasergroup_teaser__item:nth-child(2n+3) & {
      margin-top: 10px;

      @media (min-width: $screen-tablet-landscape) {
        margin-top: 20px;
      }

      @media (min-width: $screen-desktop) {
        margin-top: 40px;
      }
    }
  }

  &--variant2 {
    width: 100%;
    border: $sandLightColor 5px solid;
    padding: 32px 16px;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;

    #{$root}__link {
      width: 100%;
      text-decoration: none;
    }

    #{$root}__img {
      width: 100%;
      margin-bottom: 24px;
      display: block;

      @media (min-width: $screen-laptop) {
        margin-bottom: 40px;
      }

      img {
        width: 100%;
        display: inherit;
      }
    }

    #{$root}__header {
      @include fontSize($fontSizeXLarge);
      font-family: $fontSecondaryFamily;
      color: $brandSecondaryColor;
      margin-bottom: 24px;
      flex-grow: 1;
      vertical-align: bottom;
      font-weight: 400;

      @media (min-width: $screen-laptop) {
        margin-bottom: 40px;
      }
    }

    #{$root}__subline {
      font-size: $fontSizeXMedium;
      line-height: 135%;
      color: $grayDarkColor;
      margin-bottom: 16px;
      display: block;

      @media (min-width: $screen-laptop) {
        margin-bottom: 32px;
      }
    }

    #{$root}__button {
      flex-grow: 1;
    }
  }

  &--teaser-wide {
    min-height: 120px;
    border-radius: 4px;

    .grid & {
      @media (max-width: $screen-tablet-portrait - 1) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    #{$root}__link {
      width: 100%;
      text-decoration: none !important;
      display: flex !important;
      flex-direction: column;

      @media (min-width: $screen-tablet-portrait) {
        border-radius: 4px;
        overflow: hidden;
      }

      @media (min-width: $screen-tablet-landscape) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }
    }

    #{$root}__img-wrapper {
      position: relative;
      width: 100%;
      min-height: 120px;

      @media (min-width: $screen-tablet-landscape) {
        display: flex;
        width: 60%;
        overflow: hidden;
        justify-content: center;
      }

      .image-element__pic {
        @media (min-width: $screen-tablet-landscape) {
          height: 100%;
        }

        .is_fire-fox & { // stylelint-disable-line
          display: flex;
          justify-content: center;
        }
      }
    }

    #{$root}__img-text {
      position: absolute;
      top: 0;
      width: 100%;
      color: $whiteColor;
      text-align: center;
      display: flex;
      justify-content: center;

      h2,
      h3,
      h4 {
        padding: 16px 30px;
        line-height: 1.1;
        width: 100%;
        margin: 0;
        color: $whiteColor;
      }
    }

    #{$root}__box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 20px 24px;
      text-align: center;
      background: $sandHoverColor;

      @media (min-width: $screen-tablet-landscape) {
        text-align: left;
        padding: 16px 24px;
        width: 40%;
      }

      @media (min-width: $screen-desktop) {
        padding: 40px;
      }
    }

    #{$root}__header {
      @include teaserHeadline();
      order: unset;
      font-family: $fontHeadlineFamily;
      color: $blackColor;
      margin: 0 0 16px !important;
      padding: 0 !important;
      text-align: inherit;
    }

    #{$root}__subline {
      @include teaserText();
      display: inline-block;
      width: 100%;
      text-align: inherit;
    }

    #{$root}__button-wrapper {
      margin: 16px 0 0;
      text-align: center;
      font-size: 15px;

      @media (min-width: $screen-tablet-landscape) {
        margin: 24px 0 0;
        text-align: left;
      }
    }

    #{$root}__button {
      background: var(--button-primary-background-color);
      color: var(--button-primary-text-color);
      display: inline-block;
      text-transform: uppercase;
      padding: 0 32px;
      line-height: 30px;

      @media (min-width: $screen-tablet-portrait) {
        line-height: 42px;
      }

      @include iconFont(arrowRight, after) {
        display: inline-block;
        height: 14px;
        font-size: 12px;
        padding: 0 0 0 4px;
        vertical-align: middle;

        @media (min-width: $screen-tablet-portrait) {
          padding: 0 0 0 6px;
          height: 18px;
          font-size: 15px;
        }
      }

      &--hover,
      &:hover {
        background: var(--button-primary-hover-background-color);
        color: var(--button-primary-hover-text-color);

        &::after {
          transform: translateX(4px);
        }
      }
    }

    img {
      width: 100%;
      display: block;
      height: auto;

      @media (min-width: $screen-tablet-landscape) {
        display: block;
        height: 100%;
        width: auto;
      }
    }

    .image-element {
      display: inline;
      padding: 0;

      @media (min-width: $screen-tablet-landscape) {
        display: flex;
        padding: 0;
        justify-content: center;
        height: 100%;
        flex-direction: column;
      }
    }
  }

  &--parallax {
    height: auto;
    overflow: hidden;
    position: relative;
    max-width: none !important;
    padding: 0 !important;
    margin-bottom: 0 !important;

    #{$root}__link {
      text-decoration: none;
    }

    #{$root}__bg {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-attachment: fixed; // stylelint-disable-line
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .is_safari & {
        background-attachment: inherit;

        @media (min-width: $screen-tablet-portrait) {
          background-attachment: fixed; // stylelint-disable-line
        }
      }

      &--mobile {
        display: block;

        @media (min-width: $screen-tablet-portrait) {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @media (min-width: $screen-tablet-portrait) {
          display: block;
        }
      }
    }

    .row.grid__element--bg-color1 &,
    .row.grid__element--bg-color2 &,
    .row.grid__element--bg-color3 &,
    .row.grid__element--bg-color4 &,
    .row.grid__element--bg-color5 & {
      margin: 0 0 32px;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 0 40px;
      }
    }

    #{$root}__box {
      display: flex;
      background: transparent;
      position: relative;
      bottom: auto;
      transform: none;
      text-align: center;
      align-self: center;
      justify-content: center;
      min-height: 300px;
      margin: 20px auto;
      color: $blackColor;

      @media (min-width: $screen-tablet-landscape) {
        margin: 40px auto;
      }

      @media (min-width: $screen-desktop) {
        margin: 80px auto;
        max-width: $maxWidthInner;
      }
    }

    h1,
    h2,
    h3,
    h4 {
      margin-top: 0;
    }
  }

  &--speech-bubble {
    $bp-layout: $screen-tablet-portrait;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: $bp-layout - 1) {
      padding-bottom: 84px !important;
    }

    @media (min-width: $bp-layout) {
      flex-direction: row !important;
    }

    #{$root}__box {
      border-radius: 24px;
      background: $orangeColor;
      padding: 20px;
      text-align: center;
      margin: 0 0 -20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;

      @media (min-width: $bp-layout) {
        margin: 0 -20px 0 0;
        width: 50%;
      }
    }

    #{$root}__box-inner {
      position: relative;

      @include triangle(0, 40px, 50px, $orangeColor) {
        position: absolute;
        transform: scale(-1, -1);
        top: 100%;
        left: 20px;

        @media (min-width: $bp-layout) {
          transform: rotate(90deg);
          top: calc(100% + 16px);
          left: calc(100% + 20px);
        }
      }
    }

    #{$root}__img {
      position: relative;
      z-index: 0;
      flex: 1 1;
      border-radius: 0 0 24px 24px;
      overflow: hidden;

      @media (min-width: $bp-layout) {
        border-radius: 0 24px 24px 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    #{$root}__prelude {
      font-family: $fontSecondaryFamily;
      font-size: 30px;

      @media (min-width: $screen-tablet-sm) {
        font-size: 40px;
      }

      @media (min-width: $screen-desktop) {
        font-size: 54px;
      }
    }

    #{$root}__header {
      font-family: $fontHeadlineFamily;
      color: $whiteColor;
      font-size: 24px;
      margin: 2px 0 12px;

      @media (min-width: $screen-tablet-sm) {
        font-size: 34px;
        margin: 8px 0 24px;
      }

      @media (min-width: $screen-desktop) {
        font-size: 45px;
      }
    }

    #{$root}__text {
      color: $whiteColor;
      font-size: 13px;
      margin: 8px 0 4px;
      line-height: 1.3;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 15px;
        margin: 24px 0 0;
      }

      @media (min-width: $screen-desktop) {
        font-size: 17px;
      }
    }

    #{$root}__button-container {
      position: absolute;
      bottom: 24px;
      left: 0;
      width: 100%;

      @media (min-width: $bp-layout) {
        position: relative;
        margin: 24px 0 0;
        bottom: 0;

        .button {
          @include switchButtonStyle('primary>secondary');
        }
      }
    }
  }

  &__parallax-subline {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .grid__element--teaser & {
    #{$root}__header,
    #{$root}__subline {
      color: var(--grid-element-teaser-text-color);

      a {
        color: var(--grid-element-teaser-text-color);
      }
    }

    #{$root}__button {
      color: var(--grid-element-teaser-text-color);
      border: 1px solid var(--grid-element-teaser-text-color);
      background: transparent;

      &::after {
        color: var(--grid-element-teaser-text-color);
      }

      &:hover {
        background: transparent;
      }
    }

    #{$root}__img-wrapper,
    #{$root}__box {
      background: var(--grid-element-teaser-background);
    }
  }

  &--teaser-wide-mirrored {
    #{$root}__img-wrapper {
      order: 1;
    }
  }

  // PLACEHOLDER
  .lazy-load-image__image--not-loaded,
  lazy-load-image { // stylelint-disable-line
    min-height: 200px;
    display: block;
  }
}
