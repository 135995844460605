@import '../../dependencies.scss';

.newsletter-teaser {
  $root: &;
  background: none;
  margin-top: 8px;
  padding: 0;

  &__step-1 {
    @media (min-width: $screen-tablet-portrait-max) {
      padding: 24px;
    }
  }

  &__step-2 {
    padding: 36px 0;

    #{$root}__col-1 {
      display: none;
    }

    #{$root}__col-2 {
      padding-left: 16px;

      @media (min-width: $screen-laptop) {
        max-width: unset;
      }
    }
  }

  &__row {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-bottom: 0;
    }

    &--head {
      #{$root}__col-1 {
        display: none;
      }

      #{$root}__col-2 {
        @media (min-width: $screen-tablet-portrait) {
          padding-left: 16px;
        }
      }
    }
  }

  &__col-1 {
    img {
      width: 100%;
      display: none;

      @media (min-width: $screen-laptop) {
        display: block;
        margin: 0 24px 0 0;
        max-width: 290px;
        position: absolute;
      }

      @media (min-width: $screen-desktop) {
        max-width: none;
      }
    }
  }

  &__col-2 {
    display: flex;
    flex-direction: column;
    background-color: $sandHoverColor;
    padding: 20px 20px 24px;
    position: relative;

    @media (min-width: $screen-laptop) {
      align-items: center;
      flex-flow: row wrap;
      align-content: space-between;
      width: 100%;
      max-width: 642px;
    }

    #{$root}__text {
      margin-bottom: 32px;
      font-size: 13px;
      line-height: 145%;
      width: 100%;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 15px;
      }

      @media (min-width: $screen-desktop) {
        font-size: 17px;
      }

      p {
        margin: 8px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    #{$root}__text--empty {
      margin-bottom: 12px;
    }

    &--wrapper {
      width: 100%;
      display: flex;
      position: relative;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__form {
    margin-bottom: 16px;
  }

  &__headline {
    font-size: 21px;
    color: $blackColor;
    font-weight: $fontWeightBold;
    font-family: $fontHeadlineFamily;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 32px;
    }
  }

  &__sub-headline {
    display: block;
    font-size: $fontSizeSmall;
    color: $grayDarkColor;
    padding: 8px 0 16px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeXMedium;
    }
  }

  .content-starter-teaser__separator {
    display: $contentStarterSeparatorDisplay;
    border: none;
    border-bottom: 1px solid $dividerColor;
    background: $contentStarterSeparatorColor;
    margin: 0 16px 8px;

    @media (min-width: $screen-laptop) {
      margin: 0 16px;
    }
  }

  &__success-col > &__success {
    display: block;
  }

  &__success {
    display: none;

    #{$root}__step-2 > & {
      display: block;
      background-color: $sandHoverColor;
    }
  }

  &__steps-container {
    width: 100%;
  }

  &__next-steps {
    @include clearList();
    margin: 0;

    @media (min-width: $screen-tablet-sm) {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: start;
      margin: 0 auto 10px;
    }

    .icon::before {
      color: $brownMediumColor;
      font-size: 50px;
    }

    .icon--confirmationArrow::before {
      font-size: 14px;
      color: $grayDarkColor;
    }
  }

  &__next-step {
    font-size: $fontSizeXSmall;
    font-weight: $fontWeightSemiBold;
    margin-bottom: 40px;
    padding: 0;
    text-align: center;
    width: 100%;

    @media (min-width: $screen-tablet-sm) {
      font-size: $fontSizeSmall;
      max-width: 200px;
      padding: 0 15px;
      margin-bottom: 15px;
      flex-basis: 30%;
    }

    &:first-child {
      padding-left: 0;
    }

    &--link {
      transform: rotate(90deg);

      @media (min-width: $screen-tablet-sm) {
        transform: none;
      }
    }
  }

  &__icon-text {
    margin: 8px auto 0;
    max-width: 158px;

    @media (min-width: $screen-tablet-sm) {
      max-width: inherit;
    }
  }

  .wine-tasting & {
    background: none !important;
  }
}
// Placeholder
// stylelint-disable-next-line
newsletter-teaser {
  img {
    max-width: 290px;
  }
}

.page--wine-tasting {
  .newsletter-teaser {
    margin: 0;
  }
}

.page--content-page {
  .newsletter-teaser {
    margin-top: 40px;
  }
}
