.simple-image-teaser {
  $root: &;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  &:hover {
    #{$root}__image {
      transform: scale(1.05);
    }
  }

  &__image {
    width: 100%;
    float: left;
    transition: transform 1s ease-out;
  }

  &__text {
    font-family: $fontHeadlineFamily;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    color: $whiteColor;
    text-align: center;
    padding: 8px;
    animation: none !important;
    font-size: 24px;
    hyphens: auto;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 34px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 45px;
    }
  }
}
