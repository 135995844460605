@import '../../utilities/scss/teasers/teaserLayout.scss';

.teasergroup_teaser {
  $root: &;

  @include teaserContainer();
  margin-left: auto;
  margin-right: auto;

  .grid.teaser-in-column & {
    padding-top: 0;
    padding-bottom: 0;
  }

  .user-area__teaser-top & {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__headline {
    @include teaserHeadline();

    > #{$root}__headline {
      padding: 0;
    }
  }

  &__link {
    order: 8;
    text-align: center;
    margin: 16px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
    }
  }

  .cinema-teaser__link,
  &__link {
    .button {
      --button-secondary-text-color: #{$buttonPrimaryTextColor};
      --button-secondary-background-color: #{$buttonPrimaryBackgroundColor};
      --button-secondary-border-color: #{$buttonPrimaryBorderColor};
      --button-secondary-hover-background-color: #{$buttonPrimaryHoverBackgroundColor};
      --button-secondary-hover-text-color: #{$buttonPrimaryHoverTextColor};
      --button-secondary-active-background-color: #{$buttonPrimaryActiveBackgroundColor};
    }
  }

  &__items-container {
    @include teaserItemsContainer();
    min-width: 300px;
  }

  // For float mode
  &--float {
    $marginSize: 7px;

    #{$root}__items-container {
      width: calc(100% + (2 * #{$marginSize}));
      margin-left: -$marginSize;
      margin-right: -$marginSize;
      margin-bottom: -$marginSize * 2;
      align-items: stretch;
    }

    #{$root}__item {
      margin-bottom: $marginSize * 2;
      padding: 0 $marginSize;

      &--full-image {
        img {
          width: 100%;
        }
      }
    }

    @media (min-width: $screen-tablet-portrait) {
      $marginSize: 15px;

      #{$root}__items-container {
        width: calc(100% + (2 * #{$marginSize}));
        margin-left: -$marginSize;
        margin-right: -$marginSize;
        margin-bottom: -$marginSize * 2;
      }

      #{$root}__item {
        margin-bottom: $marginSize * 2;
        padding: 0 $marginSize;
      }
    }

    @media (min-width: $screen-desktop) {
      $marginSize: 25px;

      #{$root}__items-container {
        width: calc(100% + (2 * #{$marginSize}));
        margin-left: -$marginSize;
        margin-right: -$marginSize;
        margin-bottom: -$marginSize * 2;
      }

      #{$root}__item {
        margin-bottom: $marginSize * 2;
        padding: 0 $marginSize;
      }
    }

    .row.columns-1,
    .row.columns-2 {
      @media (min-width: $screen-desktop-xl) {
        max-width: ($maxWidthInner - 80px);
        margin: 0 auto;
      }
    }
  }

  // For slide mode
  &--slide {
    #{$root}__items-container {
      width: 100%;
    }

    #{$root}__slider-container {
      display: flex;
    }

    #{$root}__item {
      display: inline-block;
      vertical-align: top;
      width: 100%;
    }

    .teasergroup_teaser__items-container--columns-3 { // stylelint-disable-line
      @media (min-width: $screen-desktop-xl) {
        max-width: ($maxWidthInner - 80px);
        margin: 0 auto;
      }

      &:has(.product-teaser) { // stylelint-disable-line
        .carousel__button-icon {
          @media (min-width: $screen-tablet-portrait) {
            top: calc(50% - 65px);

            .content-cinema-teaser__slider & {
              top: calc(50% - 20px);
            }
          }
        }
      }
    }

    .carousel__pagination-wrapper {
      margin: 0;

      @media (min-width: $screen-tablet-sm) {
        margin: 0;
      }
    }
  }

  // For flex mode
  &--flex {
    $marginSize: 7px;

    #{$root}__items-container {
      width: calc(100% + (2 * #{$marginSize}));
      margin-left: -$marginSize;
      margin-right: -$marginSize;
      margin-bottom: -$marginSize * 2;
      align-items: stretch;
    }

    #{$root}__item {
      margin-bottom: $marginSize * 2;
      padding: 0 $marginSize;
      min-width: 100%;
    }

    @media (min-width: $screen-tablet-portrait) {
      $marginSize: 8px;

      #{$root}__items-container {
        width: calc(100% + (2 * #{$marginSize}));
        margin-left: -$marginSize;
        margin-right: -$marginSize;
        margin-bottom: -$marginSize * 2;
      }

      #{$root}__item {
        margin-bottom: $marginSize * 2;
        padding: 0 $marginSize;
        min-width: 100%;
      }
    }

    @media (min-width: $screen-tablet-landscape) {
      $marginSize: 16px;

      #{$root}__items-container {
        width: calc(100% + (2 * #{$marginSize}));
        margin-left: -$marginSize;
        margin-right: -$marginSize;
        margin-bottom: -$marginSize * 2;
      }

      #{$root}__item {
        margin-bottom: $marginSize * 2;
        padding: 0 $marginSize;
        min-width: 50%;
      }

      .col-flex--half {
        width: 50%;
      }

      .col-flex--full {
        width: 100%;
      }
    }
  }

  &--tabbed {
    --tab-color: linear-gradient(180deg, rgba(0 0 0 / .2) -66.57%, rgba(0 0 0 / 0) 52.56%), linear-gradient(0deg, rgba(59 37 12 / .15), rgba(59 37 12 / .15)); // stylelint-disable-line
    max-width: none !important;
    padding: 0 !important;
    background: $sandColor;
    position: relative;

    #{$root}__wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 0;
      overflow: hidden;
    }

    #{$root}__box {
      width: 100%;
      height: 100%;
      perspective: 780px;
      transform-style: preserve-3d;
      transform: translate3d(0, 0, 0);
      perspective-origin: 50% 50%;
      margin: 0 0 120px;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 0 105px;
      }

      @media (min-width: $screen-desktop-xl) {
        perspective: 400px;
      }
    }

    #{$root}__box-face {
      display: flex;
      align-items: center;
      justify-content: center;
      backface-visibility: inherit;
      width: 100%;
      height: 100%;
    }

    #{$root}__box-front {
      transform: translateZ(0) translateX(0) translateY(0);
      width: 100%;
    }

    #{$root}__box-back {
      background: linear-gradient(180deg, rgba(0 0 0 / .2) 0%, rgba(255 255 255 / 0) 20%, rgba(255 255 255 / 0) 80%, rgba(0 0 0 / .2) 100%); // stylelint-disable-line
      transform: rotateY(0deg) translateZ(-100px);
      position: absolute;
      top: 0;
      left: 0;

      @media (min-width: $screen-desktop-xl) {
        transform: rotateY(0deg) translateZ(-80px);
      }
    }

    #{$root}__box-top {
      background: linear-gradient(0deg, rgba(0 0 0 / .2) -66.57%, rgba(0 0 0 / 0) 52.56%), linear-gradient(0deg, rgba(59 37 12 / .15), rgba(59 37 12 / .15)); // stylelint-disable-line
      transform: rotateX(-90deg) translateZ(-50px) translateX(0) translateY(50px);
      width: 100%;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;

      @media (min-width: $screen-desktop-xl) {
        height: 80px;
        transform: rotateX(-90deg) translateZ(-40px) translateX(0) translateY(40px);
      }
    }

    #{$root}__box-bottom {
      background: var(--tab-color);
      transform: rotateX(90deg) translateZ(-50px) translateX(0) translateY(-50px);
      width: 100%;
      height: 100px;
      position: absolute;
      bottom: 0;
      left: 0;

      @media (min-width: $screen-desktop-xl) {
        height: 80px;
        transform: rotateX(90deg) translateZ(-40px) translateX(0) translateY(-40px);
      }
    }

    #{$root}__box-left {
      background: linear-gradient(99.54deg, rgba(0 0 0 / 0) 6.23%, rgba(78 47 19 /.15) 96.2%), linear-gradient(0deg, rgba(83 57 28 / .1), rgba(83 57 28 / .1)); // stylelint-disable-line
      transform: rotateY(90deg) translateZ(-50px) translateX(50px);
      width: 100px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      @media (min-width: $screen-desktop-xl) {
        width: 80px;
        transform: rotateY(90deg) translateZ(-40px) translateX(40px);
      }
    }

    #{$root}__box-right {
      background: linear-gradient(279.54deg, rgba(0 0 0 / 0) 6.23%, rgba(78 47 19 /.15) 96.2%), linear-gradient(0deg, rgba(83 57 28 / .1), rgba(83 57 28 / .1)); // stylelint-disable-line
      transform: rotateY(-90deg) translateZ(-50px) translateX(-50px);
      width: 100px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;

      @media (min-width: $screen-desktop-xl) {
        width: 80px;
        transform: rotateY(-90deg) translateZ(-40px) translateX(-40px);
      }
    }

    #{$root}__box-inner {
      width: 100%;
      position: relative;
      margin: 20px 0 40px;

      @media (min-width: $screen-tablet-portrait) {
        margin: 40px 0 60px;
        width: calc(100% - 100px);
      }

      @media (min-width: 880px) {
        margin: 40px 0 60px;
        width: calc(100% - 140px);
      }

      @media (min-width: $screen-desktop) {
        margin: 40px 0 60px;
        width: calc(100% - 180px);
      }

      @media (min-width: $screen-desktop-xl) {
        margin: 60px 0;
        width: calc(100% - 240px);
        max-width: $maxWidthInner;
      }
    }

    #{$root}__headline {
      padding: 24px 0 0;
    }

    #{$root}__items-container {
      @media (min-width: $screen-desktop-xl) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .headline--alternative {
      display: block;
      font-size: 1.2em;
      font-family: $fontSecondaryFamily;
      color: $blackColor;
      font-weight: $fontWeightRegular;
      margin: 0 0 8px;
      text-transform: none;

      @media (min-width: $screen-tablet-landscape) {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 10px;
        left: 20px;
        max-width: 240px;
        margin: 0;
        transform: rotate(-11deg);
        font-size: 1em;
      }

      @media (min-width: $screen-desktop) {
        top: 5px;
        left: 20px;
      }

      @media (min-width: $screen-desktop-xl) {
        font-size: 1.1em;
        top: 15px;
        left: 35px;
      }

      > em {
        font-style: normal;
        margin: 0 0 0 16px;

        @media (min-width: $screen-tablet-landscape) {
          margin: -5px -50px 0 0;
        }
      }
    }

    .tabs-component-panels {
      margin: 0 0 -20px;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 auto -40px;
      }

      @media (min-width: $screen-desktop-xl) {
        max-width: 1160px;
      }

      @media (min-width: $screen-desktop-xxl) {
        max-width: 1240px;
      }

      .products-list-page__content & {
        width: 100%;
      }
    }

    .product-box {
      background: transparent;
      padding: 16px 10px 32px;

      @media (min-width: $screen-tablet-portrait) {
        padding: 16px 10px 40px;
      }
    }

    .product-box__product-flags {
      height: 300px !important;

      @media (min-width: $screen-tablet-portrait) {
        height: 310px !important;
      }

      @media (min-width: $screen-desktop-xl) {
        height: 335px !important;
      }
    }

    .product-box--type-vertical {
      .product-box__image img {
        width: 110px;

        @media (min-width: $screen-tablet-portrait) {
          width: 100px;
        }

        @media (min-width: $screen-tablet-landscape) {
          width: 110px;
        }

        @media (min-width: $screen-desktop-xl) {
          width: 120px;
        }
      }

      .product-box__segment-image {
        margin: 0 0 -32px;

        @media (min-width: $screen-tablet-portrait) {
          margin: 0 0 -40px;
        }
      }
    }

    .product-box__personalization-banner {
      margin: -16px auto 8px;

      &-wrapper {
        margin: 0;
        width: 100%;
      }
    }

    .product-box--type-bottle.product-box--type-vertical .product-box__image > .image-element__pic::before {
      // stylelint-disable plugin/no-unsupported-browser-features
      background:
        transparent radial-gradient(
          ellipse at center center,
          rgb(0 0 0 / .7) 10%,
          rgb(0 0 0 / .2) 50%,
          transparent 70%
        ) repeat scroll 0 0;
      bottom: -10px;
      left: auto;
      width: 100%;
      right: 20px;
      max-width: 110px;

      @media (min-width: 880px) {
        right: 30px;
        max-width: 120px;
      }
    }

    #{$root}__link {
      margin: 24px 0 32px;
      position: absolute;
      bottom: -4px;
      width: 100%;

      @media (min-width: $screen-tablet-portrait) {
        margin: 24px 0 40px;
      }
    }

    .cinema-teaser {
      position: relative;

      &__link {
        position: absolute;
        width: 100%;
        bottom: -114px;
        left: 0;
        right: 0;

        @media (min-width: $screen-tablet-portrait) {
          bottom: -94px;
        }
      }

      .carousel__button--prev {
        @media (min-width: $screen-tablet-portrait) {
          left: -40px;
        }

        @media (min-width: 880px) {
          left: -40px;
        }
      }

      .carousel__button--next {
        @media (min-width: $screen-tablet-portrait) {
          right: -40px;
        }

        @media (min-width: 880px) {
          right: -40px;
        }
      }

      .carousel__wrapper {
        width: 100%;
        margin: 0 0 -32px;

        @media (min-width: $screen-desktop-xl) {
          margin: 0 0 -16px;
        }
      }

      .carousel__pagination-wrapper {
        @media (max-width: $screen-mobile-landscape-max) {
          display: flex;
          justify-content: center;
          width: 100%;
          margin: 0;
          align-items: center;
          padding: 0;
          position: absolute;
          bottom: -70px;
        }
      }
    }
  }

  &--asymmetric {
    .carousel__wrapper {
      width: calc(100% + 16px);
      margin: 0 -8px;

      @media (min-width: $screen-tablet-portrait) {
        width: 100%;
        margin: 0;
      }

      @media (min-width: $screen-desktop-xl) {
        margin: 0 0 -16px;
      }
    }

    .carousel__pagination-wrapper {
      margin: 8px 0 0;
    }

    .carousel__button--prev {
      left: 20px;
    }

    .carousel__button--next {
      right: 20px;
    }

    .image-slider-teaser__text {
      text-align: center;
      color: $brownMediumColor;
      font-size: 12px;
      margin: 8px 0 0;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 13px;
      }
    }

    .image-slider-teaser__image {
      border-radius: 2px;
    }

    .teasergroup_teaser__button-link {  // stylelint-disable-line selector-class-pattern
      @media (max-width: $screen-mobile-landscape-max) {
        display: flex;
        justify-content: center;
      }
    }

    .teasergroup_teaser__item {
      h2,
      .html-block h2 {
        font-family: $fontHeadlineFamily;
        font-weight: $fontWeightBold;
        font-size: 28px;
        margin: -8px 0 16px;
        text-align: center;

        @media (min-width: $screen-tablet-portrait) {
          font-size: 34px;
          margin: -8px 0 24px;
          text-align: left;
        }

        @media (min-width: $screen-desktop) {
          font-size: 45px;
        }
      }

      p,
      .html-block p {
        text-align: center;

        @media (min-width: $screen-tablet-portrait) {
          text-align: left;
        }
      }

      .image-slider-teaser {
        padding: 0;
      }
    }

    .teasergroup_teaser__item,
    .teasergroup_teaser--float {      // stylelint-disable-line selector-class-pattern
      @media (max-width: $screen-mobile-landscape-max) {
        padding: 0 16px !important;
        margin-bottom: 16px;
      }
    }

    .teasergroup_teaser--float {      // stylelint-disable-line selector-class-pattern
      #{$root}__items-container {
        width: 100%;
        margin-bottom: 16px;
        align-items: stretch;
        justify-content: center;
      }
    }
  }

  .grid__element--groupedContent1 &,
  .grid__element--groupedContent2 &,
  .grid__element--groupedContent3 & {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.row > * > .teasergroup_teaser:not(.row--full-width),
.page-home .page-main .grid > .row > * > *:not(.row--full-width) {
  &.teasergroup_teaser {
    @media (min-width: $screen-tablet-portrait) {
      padding-left: 32px;
      padding-right: 32px;
    }

    @media (min-width: $screen-desktop-sm) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
